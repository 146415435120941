// Generated by Framer (722666c)

import { addFonts, addPropertyControls, ComponentViewportProvider, cx, CycleVariantState, getFonts, getPropertyControls, SmartComponentScopedContainer, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/p8dptk4UIND8hbFWz9V7/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);
const PhosphorControls = getPropertyControls(Phosphor);

const serializationHash = "framer-zBQwg"

const variantClassNames = {xXqSEfe3I: "framer-v-17vvghi"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, iconName, id, width, ...props}) => { return {...props, i4NNbaGus: iconName ?? props.i4NNbaGus ?? "FramerLogo"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;iconName?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, i4NNbaGus, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "xXqSEfe3I", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-17vvghi", className, classNames)} data-framer-name={"Primary"} layoutDependency={layoutDependency} layoutId={"xXqSEfe3I"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-f213e283-24d0-40a3-a2dc-bca1da07b971, rgb(16, 19, 28))", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, boxShadow: "inset 0px 1px 1px 0px rgba(207, 231, 255, 0.2)", ...style}}><ComponentViewportProvider ><SmartComponentScopedContainer className={"framer-ac1sei-container"} isAuthoredByUser isModuleExternal layoutDependency={layoutDependency} layoutId={"VX7g7aV74-container"} nodeId={"VX7g7aV74"} rendersWithMotion scopeId={"T9yEeatv0"}><Phosphor color={"var(--token-f195ea74-7512-4096-8d91-0e7c7e10d0ab, rgb(213, 219, 230))"} height={"100%"} iconSearch={"House"} iconSelection={i4NNbaGus} id={"VX7g7aV74"} layoutId={"VX7g7aV74"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"regular"} width={"100%"}/></SmartComponentScopedContainer></ComponentViewportProvider></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-zBQwg.framer-dr6np9, .framer-zBQwg .framer-dr6np9 { display: block; }", ".framer-zBQwg.framer-17vvghi { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 16px; height: 52px; justify-content: center; overflow: visible; padding: 0px; position: relative; width: 54px; }", ".framer-zBQwg .framer-ac1sei-container { flex: none; height: 32px; position: relative; width: 32px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-zBQwg.framer-17vvghi { gap: 0px; } .framer-zBQwg.framer-17vvghi > * { margin: 0px; margin-left: calc(16px / 2); margin-right: calc(16px / 2); } .framer-zBQwg.framer-17vvghi > :first-child { margin-left: 0px; } .framer-zBQwg.framer-17vvghi > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 52
 * @framerIntrinsicWidth 54
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"i4NNbaGus":"iconName"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerT9yEeatv0: React.ComponentType<Props> = withCSS(Component, css, "framer-zBQwg") as typeof Component;
export default FramerT9yEeatv0;

FramerT9yEeatv0.displayName = "Icon card";

FramerT9yEeatv0.defaultProps = {height: 52, width: 54};

addPropertyControls(FramerT9yEeatv0, {i4NNbaGus: PhosphorControls?.["iconSelection"] && {...PhosphorControls["iconSelection"], defaultValue: "FramerLogo", description: undefined, hidden: undefined, title: "Icon Name"}} as any)

addFonts(FramerT9yEeatv0, [{explicitInter: true, fonts: []}, ...PhosphorFonts], {supportsExplicitInterCodegen: true})